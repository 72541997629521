@import url("https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Speedy";
  src: url("./fonts/SpeedyRegular-7BLoE.ttf") format("truetype");
}
@font-face {
  font-family: "Futurist";
  src: url("./fonts/forgotten futurist rg.otf") format("opentype");
}
.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Speedy", sans-serif;
}

p, a, li, label, button, Link, input, textarea, #threadButtons {
  font-family: "Futurist", sans-serif;
}

h1 {
  font-size: xxx-large;
}

h2 {
  font-size: xx-large;
}

h3 {
  font-size: x-large;
}

.pixel-font {
  font-family: "Pixelify Sans", sans-serif;
}

.btn-icon {
  padding: 8px;
}

.presentation-header {
  padding: 20px 40px;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.presentation-header .presTitle {
  width: 20%;
  margin-bottom: 0;
}
.presentation-header .presTitle img {
  width: 100%;
}

.presentation-body section.presentation-text {
  text-align: center;
  padding: 40px 0;
}
.presentation-body section.presentation-text .container {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
}
.presentation-body section.presentation-games .game-cards-wrapper {
  display: flex;
  justify-content: center;
  margin: 19px 0;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
  padding: 0;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card {
  transition: 400ms;
  height: 650px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card .game-title {
  font-size: xx-large;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card .game-description {
  font-size: large;
  transition: opacity 400ms;
  opacity: 0;
  max-width: 350px;
  margin: 8px 0;
  padding: 4px 0;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card .game-picture {
  transition: height 400ms;
  height: 0;
  width: 300px;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card .game-slogan {
  font-size: large;
  transition: opacity 400ms;
  opacity: 1;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card:hover {
  transform: scale(1.1, 1.1);
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card:hover .game-description {
  transition: opacity 400ms;
  opacity: 1;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card:hover .game-picture {
  transition: height 400ms;
  height: 300px;
  width: 475px;
  border-radius: 15px;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards .game-card:hover .game-slogan {
  transition: opacity 400ms;
  opacity: 0;
}
.presentation-body section.presentation-games .game-cards-wrapper .game-cards:hover > .game-card:not(:hover) {
  filter: blur(10px);
}

.login-landscape {
  background-image: url("./images/login_landscape.jpg");
  background-size: cover;
}

.odyssey .odyssey-board {
  background-image: url("./images/odyssey-board.webp");
  background-size: cover;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
}
.odyssey .odyssey-board .odyssey-tiles {
  background-image: url("./images/odyssey-tiles.webp");
  background-size: cover;
  height: 100%;
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  background-position-x: 1px;
}
.odyssey .odyssey-board .orange-planet {
  background-image: url("./images/orange-planet.jpg");
  background-size: cover;
}
.odyssey .odyssey-board .purple-planet {
  background-image: url("./images/purple-planet.jpg");
  background-size: cover;
}
.odyssey .odyssey-board .odyssey-pawn {
  transition: left 0.2s ease-in-out, top 0.2s ease-in-out;
}
.odyssey .card-animation {
  transition: 400ms;
}
.odyssey .card-animation:hover {
  transform: scale(1.1, 1.1);
}

@keyframes hoverEffect {
  to {
    translate: 0 -32px;
    transform: scale(1.5);
  }
}
.playing-card {
  width: 5vw;
  aspect-ratio: 2/3;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative; /* Allow overlapping */
  margin-left: -1vw; /* Negative margin to create overlap */
}
.playing-card.limit-battle-deck {
  width: 3.5vw;
  margin-left: -3.1vw;
}
.playing-card.safety-deck {
  width: 3.5vw;
  margin-left: 1vw;
}
.playing-card:hover {
  animation: hoverEffect 0.3s ease-in-out forwards;
  z-index: 10;
}

.draw-stack {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.5rem;
  height: 4.25vw;
  aspect-ratio: 3/2;
}

.pawn {
  transition: top 0.3s ease, left 0.3s ease;
}

.card-back, .draw-stack {
  background-image: url("images/allure/card-back.png");
}

.card-5 {
  background-image: url("images/allure/card-roulez.png");
}

.card-0 {
  background-image: url("images/allure/card-25.png");
}

.card-1 {
  background-image: url("images/allure/card-50.png");
}

.card-2 {
  background-image: url("images/allure/card-75.png");
}

.card-3 {
  background-image: url("images/allure/card-100.png");
}

.card-4 {
  background-image: url("images/allure/card-200.png");
}

.card-8 {
  background-image: url("images/allure/card-fin-limite.png");
}

.card-10 {
  background-image: url("images/allure/card-essence.png");
}

.card-12 {
  background-image: url("images/allure/card-roue-secours.png");
}

.card-14 {
  background-image: url("images/allure/card-reparation.png");
}

.card-6 {
  background-image: url("images/allure/card-stop.png");
}

.card-7 {
  background-image: url("images/allure/card-limite.png");
}

.card-9 {
  background-image: url("images/allure/card-panne-essence.png");
}

.card-11 {
  background-image: url("images/allure/card-crevaison.png");
}

.card-13 {
  background-image: url("images/allure/card-accident.png");
}

.card-15 {
  background-image: url("images/allure/card-vehicule-prioritaire.png");
}

.card-16 {
  background-image: url("images/allure/card-camion-citerne.png");
}

.card-17 {
  background-image: url("images/allure/card-increvable.png");
}

.card-18 {
  background-image: url("images/allure/card-as.png");
}

footer {
  padding: 25px;
  border-radius: 20px 20px 0 0;
}
footer div {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer div p {
  margin: 0;
}
footer div a {
  margin: 0 5px;
}
footer div button {
  border: none;
  background: none;
}

.animation-correction {
  background-image: linear-gradient(105deg, transparent 0%, transparent 40%, var(--fallback-b2, oklch(var(--b2)/1)) 50%, transparent 60%, transparent 100%) !important;
}

/*# sourceMappingURL=styles.css.map */
